import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { destroy, get, post, put } from '@/api/implementation/app';

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('api-credentials', page, perPage, search, sortBy, descending, params);
}

function show(apiCredentialId) {
  return get(`api-credentials/${apiCredentialId}`);
}

function create(apiCredential) {
  return post('api-credentials', apiCredential);
}

function update(apiCredential) {
  return put(`api-credentials/${apiCredential.id}`, apiCredential);
}

function remove(apiCredentialId) {
  return destroy(`api-credentials/${apiCredentialId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
};
