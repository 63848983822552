import Model from './model.js';

class ApiCredential extends Model {
  id = 0;
  name = '';
  key = '';
  secret = '';
  client = null;
  maximumNumberOfTokens = 1;

  mapForRequest() {
    return {
      name: this.name,
      clientId: this.client?.id,
      maximumNumberOfTokens: this.maximumNumberOfTokens,
    };
  }
}

export default ApiCredential;
