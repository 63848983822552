<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ $tc('apiCredential.title', 2) }}</h1>

    <v-alert :value="!!apiCredentials" type="success">
      <v-row class="ml-3">
        <div v-html="credentialCreatedMessage"></div>
        <VSpacer/>
        <v-btn text @click="apiCredentials=null">{{ $t('actions.close') }}</v-btn>
      </v-row>
    </v-alert>

    <Resource
        :can-update="false"
        :create-request="createHandler"
        :delete-request="deleteHandler"
        :form-component="() => import('../components/forms/ApiCredentialsForm.vue')"
        :index-request="indexHandler"
        :meta="{name: $tc('apiCredential.title', 1), namePlural: $tc('apiCredential.title', 2)}"
        :model-type="modelType"
        :show-request="showHandler"
        :table-content="tableContent"
        :can-add="can('api-credential.all')"
        :can-delete="can('api-credential.all')"
        class="apiCredential-resource"
    />
  </v-container>
</template>

<script lang="js">
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { create, index, remove, show } from '../api/endpoints/apiCredential.js';
import Client from '@/application/models/apiCredential.js';
import { mapGetters } from "vuex";

export default {
  name: 'ClientResource',
  components: {
    Resource,
  },
  data: () => ({
    apiCredentials: null,
  }),
  computed: {
    ...mapGetters("profile", ["can"]),
    indexHandler: () => index,
    showHandler: () => show,
    deleteHandler: () => remove,
    modelType: () => Client,
    credentialCreatedMessage() {
      return this.$t('apiCredential.createdMessage', this.apiCredentials)
    },
    tableContent() {
      return [
        {
          text: this.$t('apiCredential.fields.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('apiCredential.fields.client'),
          align: 'left',
          sortable: true,
          value: 'clientName',
        },
        {
          text: this.$t('apiCredential.fields.key'),
          align: 'left',
          sortable: true,
          value: 'key',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'apiCredential.index' },
        text: this.$tc('apiCredential.title', 2),
      },
    ]);
  },
  methods: {
    createHandler(...args) {
      return create(...args).then((response) => {
        this.apiCredentials = response.data.data;
      });
    },
  }
};
</script>
